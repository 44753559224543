<template>
  <div>
    <span class="ml-2"> {{ $t("timeZone") }} </span>
    <v-row class="mb-sm-n4 mb-n10">
      <v-col sm="8" md="5" cols="12">
        <v-autocomplete
          style="min-width: 300px"
          v-model="selectedTimeZone"
          :items="timeZones"
          outlined
          dense
          @change="timeZoneChanged"
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> mdi-information-outline </v-icon>
              </template>
              {{ $t("toolTips.timeZoneInfo") }}
            </v-tooltip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col sm="4" md="7" cols="0"></v-col>
    </v-row>
    <v-snackbar right top app color="success" v-model="timeZoneSuccessfully">
      {{ $t("notificationSnackbars.timeZoneSuccessfully") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="timeZoneSuccessfully = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="timeZoneError">
      {{ $t("notificationSnackbars.timeZoneError") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="timeZoneError = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {timeZones} from "./timeZones";
import {patchUser} from "../../../services/backendService";

export default {
  data() {
    return {
      selectedTimeZone: "",
      timeZones: timeZones,

      timeZoneSuccessfully: false,
      timeZoneError: false,
    };
  },
  methods: {
    async timeZoneChanged() {
      const bodyToSend = {
        timeZone: this.selectedTimeZone,
      };
      const response = await patchUser(bodyToSend);
      if (!response) {
        this.timeZoneError = true;
      } else {
        this.timeZoneSuccessfully = true;
        this.$store.dispatch("changeUserTimeZone",this.selectedTimeZone);
      }
    },
  },
  created() {
    this.selectedTimeZone = this.$store.state.user.timeZone;
  },
};
</script>

<style scoped></style>
